
<template>
  <router-view />
</template>
<script>
window.document.oncontextmenu = function () {
  return false;
};

document.documentElement.addEventListener(
  "touchstart",
  function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  },
  false
);

//检测全屏
// const fullscreenchange = () => {
//   let isFullScreen =
//     document.fullScreen ||
//     document.mozFullScreen ||
//     document.webkitIsFullScreen;
//   if (isFullScreen) {
//     //  进入全屏
//     alert("full！！！");
//   } else {
//     //  退出全屏
//     alert("exit full！！！");
//   }
// };
// ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange"].forEach(
//   (item, index) => {
//     window.addEventListener(item, () => fullscreenchange());
//   }
// );

//检测横屏
// window.addEventListener(
//   "onorientationchange" in window ? "orientationchange" : "resize",
//   function () {
//     if (!checkWx()) {
//       if (window.orientation === 180 || window.orientation === 0) {
//         alert("pass_竖向,reload");
//         // window.location.reload();
//       }
//       if (window.orientation === 90 || window.orientation === -90) {
//         alert("pass_横向,reload");
//         // window.location.reload();
//       }
//     }
//   },
//   false
// );

const checkFullScreen = () => {
  var isFull =
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement;
  if (isFull == null || isFull == undefined) {
    isFull = false;
  }
  return isFull;
};

const checkWx = () => {
  var ua = navigator.userAgent.toLowerCase();
  var isWeixin = ua.indexOf("micromessenger") != -1;
  if (isWeixin) {
    undefined;
    return true;
  } else {
    undefined;
    return false;
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
div,
span,
p {
  font-size: 12px;
}
p {
  margin: 0;
}
@import "../src/assets/css/common.css";
</style>
